import cs from 'classnames';
import React from 'react';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { Search } from '../Search/Search';
import { HeaderStore } from '../../stores/HeaderStore';
import { RoutingStore } from '../../stores/RoutingStore';
import { WithTranslations, withTranslations } from '../../../utils/withTranslations';
import { headerTitleDataHooks } from './HeaderTitle.dataHooks';
import styles from './HeaderTitle.scss';

interface HeaderTitleProps extends WithTranslations {
  children?: React.ReactNode;
  dataHook?: string;
  focusOnSubTitle?: () => void;
  headerStore: HeaderStore;
  routingStore: RoutingStore;
}

const HeaderTitleCmp: React.FC<HeaderTitleProps> = ({ t, dataHook, headerStore, routingStore }) => {
  const HeadingTagName = routingStore.matchedRoute.routeName === 'home' ? 'h1' : 'div';
  const { isLargeSearchInput, isSearchInputFocused } = headerStore;

  return (
    <div className={cs(styles.header, { [styles.column]: isLargeSearchInput })} data-hook={dataHook}>
      <HeadingTagName
        className={cs(styles.title, {
          [styles.smallText]: !isLargeSearchInput && isSearchInputFocused,
          [styles.centerAligned]: isLargeSearchInput,
        })}
        data-hook={headerTitleDataHooks.title()}
      >
        {t('header.title.v2')}
      </HeadingTagName>
      {!headerStore.isSticky && <Search dataHook={headerTitleDataHooks.search()} />}
    </div>
  );
};

export const HeaderTitle = withTranslations(injectStoresV2('headerStore', 'routingStore')(HeaderTitleCmp));
