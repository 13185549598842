import React from 'react';
import { injectStoresV2 } from '../../stores/injectStoresV2';
import { ExperimentsStore } from '../../../stores/ExperimentsStore';
import { TemplatesStore } from '../../stores/TemplatesStore';
import { Sort } from '../Sort/Sort';
import { RoutingStore } from '../../stores/RoutingStore';
import { SubTitle } from './SubTitle/SubTitle';
import { SubText } from './SubText/SubText';
import { SubHeaderBreadcrumbs } from './SubHeaderBreadcrumbs/SubHeaderBreadcrumbs';
import { SubCategories } from './SubCategories/SubCategories';
import { subHeaderDataHooks } from './SubHeader.dataHooks';
import s from './SubHeader.scss';

interface SubHeaderProps {
  experimentsStore: ExperimentsStore;
  routingStore: RoutingStore;
  templatesStore: TemplatesStore;
  dataHook?: string;
}

const SubHeaderCmp: React.FC<SubHeaderProps> = ({ dataHook, experimentsStore, routingStore, templatesStore }) => {
  const { activeSubCategory, activeCategory } = templatesStore;

  const isSortingEnabled = () => {
    if (!experimentsStore.is('specs.funnel.TemplatesGalleryEnableSortingDesktop', 'true')) {
      return false;
    }

    if (activeCategory && activeCategory.type === 'main' && activeCategory.name !== 'all') {
      return false;
    }

    if (!!routingStore.activeSearchCriteria && templatesStore.isNoResultSuggestions) {
      return false;
    }

    return true;
  };

  const hasActiveSubCategory = !!activeSubCategory;

  return (
    <div className={hasActiveSubCategory ? s.subHeader : s.subHeaderWithTopMargin} data-hook={dataHook}>
      {hasActiveSubCategory && <SubHeaderBreadcrumbs dataHook={subHeaderDataHooks.breadcrumbs()} />}
      <div className={s.subTitleSortWrapper}>
        <SubTitle dataHook={subHeaderDataHooks.subTitle()} />
        {isSortingEnabled() && <Sort dataHook={subHeaderDataHooks.sortMenu()} />}
      </div>
      <SubText dataHook={subHeaderDataHooks.subText()} />
      <SubCategories dataHook={subHeaderDataHooks.subCategories()} />
    </div>
  );
};

export const SubHeader = injectStoresV2('experimentsStore', 'routingStore', 'templatesStore')(SubHeaderCmp);
