import { IReactionDisposer, reaction } from 'mobx';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import webBiLogger from '@wix/web-bi-logger';

import {
  templatesStickyHeader,
  templatesPageGalleryFilterPane,
  templatesSearch,
  templatesPageMainCategoryMenu,
  templatesPageSubCategoryFilter,
  templatesTemplateAboveTheFoldImpression,
  templatesPagination,
  templatesStartedLoadingThePage,
  templatesFinishedLoadingThePage,
  templatesPagePageFullyRendered,
  templatesClickOnElement,
  templatesViewTemplate,
  templatesOpenEditor,
  templatesClickedDescription,
  templatesClosedDescription,
  templatesHoverOnTemplate,
  templatesGenericPageView,
  clickAdiBannerInTemplatesPage,
  sort,
  templatesPageWebVitalsMetrics,
  templatesSawAnElement,
} from '@wix/bi-logger-spettatore-bi/v2';
import {
  clickAdiBannerInTemplatesPageParams,
  templatesViewTemplateParams,
  templatesGenericPageViewParams,
  templatesOpenEditorParams,
  templatesPageMainCategoryMenuParams,
  templatesSearchParams,
} from '@wix/bi-logger-spettatore-bi/v2/types';
import {
  introBusinessTypeTypingClientEvent,
  introSelectBusinessTypePageNavigationClick,
} from '@wix/bi-logger-onboarding/v2';

import { ReportProps } from '@wix/web-bi-logger/dist/src/types';
import { BaseBILogger } from '../../BI/BaseBILogger';
import { mapTemplatesToJson } from '../../utils/templatesToBiData';
import { WebVitalsMetric } from '../../BI/webVitals';
import { CategoriesStore } from './CategoriesStore';
import { RoutingStore } from './RoutingStore';
import { FilterCollection, FilterKey } from './routes/sortFiltersParams';
import { TemplatesStore, TemplateStoreFetchParams } from './TemplatesStore';
import { Interaction } from './Interaction';
import { HeaderStore } from './HeaderStore';
import { getFilterAndSortParameters } from './routes/route';
import { BaseURLStore } from './BaseURLStore';
import { GallerySearchSuggestionItem, SearchType } from './SearchWithSuggestionsStore';
import { ConfigStore } from './ConfigStore';

export interface ViewTemplateBI {
  siteId: string;
  galleryDocIndex: number;
  openingBrowserUrl: string;
  hasVideo: boolean;
  origin: 'create-page' | 'thumbnail' | 'video_preview';
  score?: number;
  currentPageNumber: number;
  buttonName?: 'try' | 'view';
  isBestMatch?: boolean;
}

export interface EditTemplateBI {
  siteId: string;
  galleryDocIndex: number;
  openingBrowserUrl: string;
  metaSiteId: string;
  editorSessionId: string;
  hasVideo: boolean;
  origin: 'create-page' | 'video_preview';
  score?: number;
  currentPageNumber: number;
  isBestMatch?: boolean;
}

interface SearchTermChangeBI {
  criteria: string;
  suggestionsTotalCount: number;
}

interface SearchSuggestionSelectBI {
  suggestion: GallerySearchSuggestionItem | null;
  searchType: SearchType;
  criteria: string;
}

export interface TemplateVideoPreviewOpenedBI {
  siteId: string;
}

export interface TemplateVideoPreviewClosedBI {
  siteId: string;
  videoFullDuration?: number;
  videoPlaybackPosition?: number;
  videoStartTime?: number;
  videoEndTime?: number;
  videoStatus?: 'have_nothing' | 'have_metadata' | 'have_current_data' | 'have_future_data' | 'have_enough_data';
}

export type InteractionType = 'search' | 'pagination' | 'category_switch';

export interface SubCategoryBI {
  selected_value: string;
  previous_value: string;
}

type TemplatesFilterPaneAction = 'open' | 'close' | 'select' | 'unselect' | 'clear' | 'hover' | 'show' | 'hide';
type FilterElements = 'all' | 'sort';

interface LogFilterParams {
  type: keyof FilterCollection | FilterElements;
  previousValues: FilterCollection;
  selectedValues?: FilterCollection;
  clickedItemValue?: FilterKey;
}

interface TemplatesFilterPaneParams extends LogFilterParams {
  action: TemplatesFilterPaneAction;
  selectedValues: FilterCollection;
}

interface ClickOnElementsBI {
  clicked_element: string;
  clicked_element_identifier: string;
  galleryDocIndex?: number;
  clickedTemplateHasVideo?: boolean;
  openingBrowserUrl?: string;
}

export interface LogSearchParams {
  criteria: string;
  totalPages: number;
  currentPage: number;
  numberOfTemplatesInCurrentPage: number;
  source: string;
  searchId: string;
  selectedSuggestion?: GallerySearchSuggestionItem | null;
  searchType?: SearchType;
}

export interface LogTemplateHoverParams {
  galleryDocIndex: number;
  duration: number;
  product: string;
  templateId: string;
  hasVideo: boolean;
  siteId: string;
}

export interface LogSawElementParams {
  elementName: string;
}

interface AboveTheFoldImpressionBI {
  galleryDocIndex: number;
  template_id: string;
}

export interface CategorySelectParams {
  category: string;
  subCategory?: string;
  clickSource: 'header' | 'tags';
}

export interface LogSortParams {
  actionName: 'expand' | 'execute';
  selectedSort: string;
}

export interface LogCategorySortParams extends LogSortParams {
  categorySlug: string;
}

export interface LogSubCategorySortParams extends LogCategorySortParams {
  subCategorySlug: string;
}

export interface LogSearchSortParams extends LogSortParams {
  searchCriteria: string;
}

export type screenType = 'main' | 'search' | 'category';

interface InitParams {
  routingStore: RoutingStore;
  categoriesStore: CategoriesStore;
  templatePageSessionId: string;
  templatesStore: TemplatesStore;
  headerStore: HeaderStore;
  baseURLStore: BaseURLStore;
  configStore: ConfigStore;
}

export class BILoggerStore implements BaseBILogger {
  private logger = webBiLogger.factory().logger();
  private log(payload: ReportProps): Promise<any> {
    const cleanPayload: ReportProps = { ...payload, params: omitBy(payload.params, isNil) };

    return this.logger.report(cleanPayload);
  }

  private routingStore: RoutingStore;
  private categoriesStore: CategoriesStore;
  private headerStore: HeaderStore;
  private baseURLStore: BaseURLStore;
  private templatesStore: TemplatesStore;
  private configStore: ConfigStore;

  private interactions: Map<string, Interaction> = new Map();
  private lastInteractionKey: string = null;

  public templatePageSessionId: string;

  private stickyHeaderReactionDisposer: IReactionDisposer = null;

  init({
    routingStore,
    categoriesStore,
    templatesStore,
    headerStore,
    baseURLStore,
    templatePageSessionId,
    configStore,
  }: InitParams) {
    this.routingStore = routingStore;
    this.categoriesStore = categoriesStore;
    this.templatesStore = templatesStore;
    this.headerStore = headerStore;
    this.baseURLStore = baseURLStore;
    this.templatePageSessionId = templatePageSessionId;
    this.configStore = configStore;

    this.listenForStickyHeaderChange();
  }

  private listenForStickyHeaderChange() {
    if (this.stickyHeaderReactionDisposer) {
      this.stickyHeaderReactionDisposer();
    }

    this.stickyHeaderReactionDisposer = reaction(
      () => this.headerStore.isSticky,
      () => {
        const { items } = this.templatesStore;

        return this.log(
          templatesStickyHeader({
            ...this.getActiveCategoryAndSubCategory(),
            ...this.getCurrentHeaderType(),
            galleryDocsPerPage: items.length,
            event_data: mapTemplatesToJson(items),
            tpsi: this.templatePageSessionId,
          }),
        );
      },
    );
  }

  private getCurrentHeaderType(): { header_type: 'sticky' | 'default' } {
    return {
      header_type: this.headerStore.isSticky ? 'sticky' : 'default',
    };
  }

  public initInteraction(interactionType: InteractionType, startTime?: number) {
    const interaction = new Interaction(interactionType, startTime);
    this.lastInteractionKey = interaction.key;
    this.interactions.set(interaction.key, interaction);
    return interaction;
  }

  public getLastInteractionKey() {
    return this.lastInteractionKey;
  }

  private eraseLastInteractionKey() {
    this.lastInteractionKey = null;
  }

  private static getWindowParams() {
    return typeof window !== 'object'
      ? {}
      : {
          window_width: window.innerWidth,
          window_height: window.innerHeight,
          screen_width: window.screen.width,
          screen_height: window.screen.height,
          avail_width: window.screen.availWidth,
          avail_height: window.screen.availHeight,
        };
  }

  private static getCategoryAndSubCategory = (fetchParams: TemplateStoreFetchParams) =>
    fetchParams?.type === 'search'
      ? {
          category: 'search',
          sub_category: '',
        }
      : {
          category: fetchParams?.categorySlug ?? '',
          sub_category: fetchParams?.subCategorySlug ?? '',
        };

  private getBook = () => ({ book: this.categoriesStore.bookName });

  private getActiveCategoryAndSubCategory = () =>
    BILoggerStore.getCategoryAndSubCategory(this.templatesStore.fetchParams);

  private getActiveCategoryAndBook = () => ({
    ...this.getActiveCategoryAndSubCategory(),
    ...this.getBook(),
  });

  private getLoadedCategoryAndBook = () => ({
    ...BILoggerStore.getCategoryAndSubCategory(this.templatesStore.loadedFetchParams),
    ...this.getBook(),
  });

  private getImplicitFields = () => ({
    page_index: this.routingStore.currentPage,
    tpsi: this.templatePageSessionId,
  });

  private get screenType(): screenType {
    if (this.templatesStore.loadedFetchParams?.criteria) {
      return 'search';
    }
    if (this.templatesStore.loadedFetchParams?.categorySlug === 'all') {
      return 'main';
    }
    if (this.templatesStore.loadedFetchParams?.type === 'category') {
      return 'category';
    }
    return null;
  }

  private templatesFilterPane({
    type,
    selectedValues,
    previousValues,
    action,
    clickedItemValue,
  }: TemplatesFilterPaneParams) {
    const { book, category, sub_category } = this.getActiveCategoryAndBook();
    this.log(
      templatesPageGalleryFilterPane({
        action,
        menu_name: type,
        updated_values: JSON.stringify(selectedValues),
        previous_values: JSON.stringify(previousValues),
        updated_features: selectedValues.features.join(','),
        updated_layouts: selectedValues.layouts.join(','),
        updated_colors: selectedValues.colors.join(','),
        updated_styles: selectedValues.colorStyles.join(','),
        clicked_value: clickedItemValue ?? '',
        book,
        category,
        subcategory: sub_category,
        ...this.getImplicitFields(),
      }),
    );
  }

  public logTemplatesLoaded() {
    const { category, sub_category } = this.getActiveCategoryAndBook();
    const {
      previousLocation,
      matchedRoute: { routeName },
      currentPage,
    } = this.routingStore;
    const isSearch = routeName === 'search';
    const { params } = this.routingStore.matchedRoute;
    const criteria = 'criteria' in params ? params.criteria : '';
    const origin = this.configStore.config.introReferrer || 'organic';
    const { screen_width, screen_height, ...windowParams } = BILoggerStore.getWindowParams();

    const requiredFields: templatesGenericPageViewParams = {
      category,
      sub_category,
      tpsi: this.templatePageSessionId,
      origin,
      criteria,
      is_search_result: isSearch,
      screen_width,
      screen_height,
    };

    const legacyFields: templatesGenericPageViewParams = {
      gallerydocsperpage: this.templatesStore.items.length,
      search_guid: this.templatesStore.searchId ?? '',
      screen_type: this.screenType,
      previous_url: previousLocation ? this.baseURLStore.buildURL(previousLocation) : document.referrer,
      eventData: mapTemplatesToJson(this.templatesStore.items).toString(),
      total_search_results: this.templatesStore.itemsTotal,
      page_number: currentPage || 1,
      ...windowParams,
      ...getFilterAndSortParameters(this.templatesStore.fetchParams),
    };

    return this.log(
      templatesGenericPageView({
        ...requiredFields,
        ...legacyFields,
      }),
    );
  }

  public logTemplateEdit(params: EditTemplateBI) {
    const isSearch = this.templatesStore.loadedFetchParams?.type === 'search';
    const {
      galleryDocIndex,
      openingBrowserUrl,
      metaSiteId,
      editorSessionId,
      siteId,
      hasVideo,
      origin,
      score,
      currentPageNumber,
      isBestMatch,
    } = params;
    const { category, sub_category, book } = this.getLoadedCategoryAndBook();

    const requiredFields: templatesOpenEditorParams = {
      page_number: currentPageNumber,
      category,
      openingSiteID: siteId,
      metasiteid: metaSiteId,
      is_search_result: isSearch,
      index: galleryDocIndex,
      sub_category,
      origin,
      tpsi: this.templatePageSessionId,
      is_recommended: Boolean(isBestMatch),
    };

    const legacyFields: templatesOpenEditorParams = {
      book,
      openingBrowserUrl,
      esi: editorSessionId,
      btn: true,
      search_guid: this.templatesStore.searchId ?? '',
      siteType: 2,
      total_search_results: this.templatesStore.itemsTotal,
      score_vespa: String(score ?? ''),
      template_type: 'editor',
      videoThumbnail: hasVideo,
      screen_type: this.screenType,
      openingSiteID: siteId,
      ...getFilterAndSortParameters(this.templatesStore.loadedFetchParams),
      ...BILoggerStore.getWindowParams(),
    };

    return this.log(
      templatesOpenEditor({
        ...requiredFields,
        ...legacyFields,
      }),
    );
  }

  public logTemplateView({ buttonName = 'view', ...params }: ViewTemplateBI) {
    const isSearch = this.templatesStore.loadedFetchParams?.type === 'search';
    const { galleryDocIndex, openingBrowserUrl, siteId, hasVideo, origin, score, currentPageNumber, isBestMatch } =
      params;
    const { book, category, sub_category } = this.getLoadedCategoryAndBook();

    const requiredFields: templatesViewTemplateParams = {
      openingSiteID: siteId,
      index: galleryDocIndex,
      is_search_result: isSearch,
      category,
      sub_category,
      pageNumber: currentPageNumber,
      button_name: buttonName,
      is_recommended: Boolean(isBestMatch),
    };

    const legacyFields: templatesViewTemplateParams = {
      openingBrowserUrl,
      btn: true,
      search_guid: this.templatesStore.searchId ?? '',
      siteType: 2,
      total_search_results: this.templatesStore.itemsTotal,
      tpsi: this.templatePageSessionId,
      origin,
      score_vespa: String(score ?? ''),
      videoThumbnail: hasVideo,
      screen_type: this.screenType,
      openingSiteID: siteId,
      book,
      ...getFilterAndSortParameters(this.templatesStore.loadedFetchParams),
      ...BILoggerStore.getWindowParams(),
    };

    return this.log(
      templatesViewTemplate({
        ...requiredFields,
        ...legacyFields,
      }),
    );
  }

  public logCategorySelect(params: CategorySelectParams) {
    const { clickSource, category, subCategory } = params;
    const { tpsi } = this.getImplicitFields();

    const requiredFields: templatesPageMainCategoryMenuParams = {
      click_source: clickSource,
      tpsi,
      category,
      sub_category: subCategory,
    };

    const legacyFields: templatesPageMainCategoryMenuParams = {
      action: 'select',
      ...this.getCurrentHeaderType(),
    };

    return this.log(
      templatesPageMainCategoryMenu({
        ...requiredFields,
        ...legacyFields,
      }),
    );
  }

  public logSearch(params: LogSearchParams) {
    const { previousMatchedRoute } = this.routingStore;

    let previousCategory = '';
    let previousSubCategory = '';

    if (previousMatchedRoute) {
      if (previousMatchedRoute.routeName === 'search') {
        previousCategory = 'search';
      }

      if (previousMatchedRoute.routeName === 'home') {
        previousCategory = this.routingStore.primaryCategorySlug;
      }

      if (previousMatchedRoute.routeName === 'category' || previousMatchedRoute.routeName === 'subCategory') {
        previousCategory = previousMatchedRoute.params.categorySlug;
      }

      if (previousMatchedRoute.routeName === 'subCategory') {
        previousSubCategory = previousMatchedRoute.params.subCategorySlug;
      }
    }

    const requiredFields: templatesSearchParams = {
      category: 'search',
      tpsi: this.templatePageSessionId,
      criteria: params.criteria,
    };

    let click_source = this.headerStore.isSticky ? 'Sticky Header' : 'Main Header';
    if (this.configStore.config.introReferrer === 'ai_assistant') {
      click_source = 'template-assistant';
    }

    const legacyFields: templatesSearchParams = {
      click_source,
      source: params.source,
      previous_category: previousCategory,
      previous_sub_category: previousSubCategory,
      currentPage: params.currentPage,
      totalPages: params.totalPages,
      numberOfTemplatesInCurrentPage: params.numberOfTemplatesInCurrentPage,
      search_guid: params.searchId,
      selectedIndustryId: params.selectedSuggestion?.industryId ?? '',
      selectedStructureId: params.selectedSuggestion?.structureId ?? '',
      searchType: params.searchType ?? '',
      book: this.categoriesStore.bookName,
      ...getFilterAndSortParameters(this.templatesStore.fetchParams),
    };

    return this.log(
      templatesSearch({
        ...requiredFields,
        ...legacyFields,
      }),
    );
  }

  public logTemplateInViewport(props: AboveTheFoldImpressionBI) {
    return this.log(
      templatesTemplateAboveTheFoldImpression({
        galleryDocsPerPage: this.templatesStore.items.length,
        siteType: 2,
        template_id: props.template_id,
        galleryDocIndex: props.galleryDocIndex,
        ...this.getLoadedCategoryAndBook(),
        ...BILoggerStore.getWindowParams(),
        ...getFilterAndSortParameters(this.templatesStore.loadedFetchParams),
        ...this.getImplicitFields(),
      }),
    );
  }

  public logWebVitals(props: WebVitalsMetric) {
    const { name, id } = props;
    let { value, delta } = props;

    // CLS values low (0.01 etc.), so we multiplying them before rounding
    if (name === 'CLS') {
      value = value * 1000;
      delta = delta * 1000;
    }

    // BI event requires Int, not Float numbers
    value = Math.round(value);
    delta = Math.round(delta);

    return this.log(
      templatesPageWebVitalsMetrics({
        metric_name: name,
        metric_value: value,
        id,
        delta,
      }),
    );
  }

  // deprecated events 👇

  public logCloseFiltersElement(params: LogFilterParams) {
    this.templatesFilterPane({
      action: 'close',
      selectedValues: params.previousValues,
      ...params,
    });
  }

  public logOpenFiltersElement(params: LogFilterParams) {
    this.templatesFilterPane({
      action: 'open',
      selectedValues: params.previousValues,
      ...params,
    });
  }

  public logShowFiltersPanel(params: LogFilterParams) {
    this.templatesFilterPane({
      action: 'show',
      selectedValues: params.previousValues,
      ...params,
    });
  }

  public logHideFiltersPanel(params: LogFilterParams) {
    this.templatesFilterPane({
      action: 'hide',
      selectedValues: params.previousValues,
      ...params,
    });
  }

  public logHoverFilter(params: LogFilterParams) {
    this.templatesFilterPane({
      action: 'hover',
      selectedValues: params.previousValues,
      ...params,
    });
  }

  public logSelectFilter(params: LogFilterParams) {
    this.templatesFilterPane({
      action: 'select',
      selectedValues: params.selectedValues,
      ...params,
    });
  }

  public logUnselectFilter(params: LogFilterParams) {
    this.templatesFilterPane({
      action: 'unselect',
      selectedValues: params.selectedValues,
      ...params,
    });
  }

  public logClearFilters(params: LogFilterParams) {
    this.templatesFilterPane({
      action: 'clear',
      selectedValues: params.selectedValues,
      ...params,
    });
  }

  public logSubCategoryAdd(props: SubCategoryBI) {
    const { book, category } = this.getActiveCategoryAndBook();
    this.log(
      templatesPageSubCategoryFilter({
        action: 'add',
        book,
        category,
        ...props,
        ...this.getImplicitFields(),
      }),
    );
  }

  public logSubCategoryRemove(props: SubCategoryBI) {
    const { book, category } = this.getActiveCategoryAndBook();
    this.log(
      templatesPageSubCategoryFilter({
        action: 'remove',
        book,
        category,
        ...props,
        ...this.getImplicitFields(),
      }),
    );
  }

  public logSubCategoryClear(props: SubCategoryBI) {
    const { book, category } = this.getActiveCategoryAndBook();
    this.log(
      templatesPageSubCategoryFilter({
        action: 'clear',
        book,
        category,
        ...props,
        ...this.getImplicitFields(),
      }),
    );
  }

  public logPagination(page: number) {
    this.log(
      templatesPagination({
        pagination_type: 'button',
        page_clicked_index: page,
        category: this.getActiveCategoryAndBook().category,
        ...this.getImplicitFields(),
      }),
    );
  }

  public logStartedLoadingThePage(key: string) {
    const interaction = this.interactions.get(key);

    if (interaction) {
      interaction.reportLoadingStart();
      this.log(
        templatesStartedLoadingThePage({
          galleryDocsPerPage: this.templatesStore.items.length,
          interaction_type: interaction.type,
          platform: 'web',
          product: 'html',
          ...this.getActiveCategoryAndBook(),
          ...getFilterAndSortParameters(this.templatesStore.fetchParams),
          ...this.getImplicitFields(),
        }),
      );
    }
  }

  public logFinishedLoadingThePage(key: string) {
    const interaction = this.interactions.get(key);

    if (interaction) {
      const templates = this.templatesStore.items;

      this.log(
        templatesFinishedLoadingThePage({
          event_data: mapTemplatesToJson(templates).toString(),
          galleryDocsPerPage: templates.length,
          interaction_type: interaction.type,
          loadingTime: Date.now() - interaction.startTime,
          platform: 'web',
          product: 'html',
          ...this.getActiveCategoryAndBook(),
          ...getFilterAndSortParameters(this.templatesStore.fetchParams),
          ...this.getImplicitFields(),
        }),
      );
    }
  }

  public logPageFullyRendered(key: string) {
    const interaction = this.interactions.get(key);
    if (!interaction) {
      return;
    }

    this.interactions.delete(key);
    if (key === this.getLastInteractionKey()) {
      this.eraseLastInteractionKey();
    }

    this.log(
      templatesPagePageFullyRendered({
        event_data: mapTemplatesToJson(this.templatesStore.items).toString(),
        gallerydocsperpage: this.templatesStore.items.length,
        interaction_type: interaction.type,
        loadingTime: Date.now() - interaction.startTime,
        ...this.getLoadedCategoryAndBook(),
        ...getFilterAndSortParameters(this.templatesStore.loadedFetchParams),
        ...this.getImplicitFields(),
      }),
    );
  }

  public logClickElement(params: ClickOnElementsBI) {
    const { galleryDocIndex, openingBrowserUrl, clicked_element, clickedTemplateHasVideo, clicked_element_identifier } =
      params;

    this.log(
      templatesClickOnElement({
        clicked_element,
        clicked_element_identifier,
        galleryDocIndex,
        gallerydocsperpage: this.templatesStore.items.length,
        videoThumbnail: clickedTemplateHasVideo,
        openingBrowserUrl,
        screen_type: this.screenType,
        ...this.getActiveCategoryAndBook(),
        ...this.getImplicitFields(),
        ...this.getCurrentHeaderType(),
      }),
    );
  }

  public logTemplateInfoShown() {
    this.log(
      templatesClickedDescription({
        galleryDocsPerPage: this.templatesStore.items.length,
        tpsi: this.templatePageSessionId,
      }),
    );
  }

  public logTemplateInfoHidden() {
    this.log(
      templatesClosedDescription({
        galleryDocsPerPage: this.templatesStore.items.length,
        tpsi: this.templatePageSessionId,
      }),
    );
  }

  public logTemplateHover(params: LogTemplateHoverParams) {
    const { galleryDocIndex, duration, product, templateId, siteId, hasVideo } = params;

    this.log(
      templatesHoverOnTemplate({
        openingSiteID: siteId,
        template_id: templateId,
        siteType: 2,
        product,
        origin: '',
        btn: false,
        hover_ms: duration,
        index: galleryDocIndex,
        galleryDocIndex,
        galleryDocsPerPage: this.templatesStore.items.length,
        videoThumbnail: hasVideo,
        tpsi: this.templatePageSessionId,
        screen_type: this.screenType,
        ...this.getLoadedCategoryAndBook(),
        ...BILoggerStore.getWindowParams(),
        ...getFilterAndSortParameters(this.templatesStore.loadedFetchParams),
        ...this.getImplicitFields(),
      }),
    );
  }

  public logAdiSearchTermChangeAdiBiEvent = ({ criteria, suggestionsTotalCount }: SearchTermChangeBI) => {
    this.log(
      introBusinessTypeTypingClientEvent({
        flow: 'marketing templates',
        returnedResults: suggestionsTotalCount,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        text: criteria,
      }),
    );
  };

  public logSearchSuggestionSelectAdiBiEvent = ({ suggestion, searchType, criteria }: SearchSuggestionSelectBI) => {
    this.log(
      introSelectBusinessTypePageNavigationClick({
        flow: 'marketing templates',
        mainCategory: suggestion?.postRegVertical ?? '',
        name: suggestion?.text ?? '',
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        selectedIndustryId: suggestion?.industryId ?? '',
        selectedStructureId: suggestion?.structureId ?? '',
        suggestList: searchType,
        text: criteria,
      }),
    );
  };

  public logAdiBannerClick = (props: clickAdiBannerInTemplatesPageParams) => {
    this.log(clickAdiBannerInTemplatesPage(props));
  };

  public logCategorySort = ({ actionName, selectedSort, categorySlug }: LogCategorySortParams) => {
    this.log(
      sort({
        actionName,
        values: selectedSort,
        category: categorySlug,
      }),
    );
  };

  public logSubCategorySort = ({
    actionName,
    selectedSort,
    categorySlug,
    subCategorySlug,
  }: LogSubCategorySortParams) => {
    this.log(
      sort({
        actionName,
        values: selectedSort,
        category: categorySlug,
        subcategory: subCategorySlug,
      }),
    );
  };

  public logSawElement = ({ elementName }: LogSawElementParams) => {
    this.log(
      templatesSawAnElement({
        element_name: elementName,
      }),
    );
  };

  public logSearchSort = ({ actionName, selectedSort, searchCriteria }: LogSearchSortParams) => {
    this.log(
      sort({
        actionName,
        values: selectedSort,
        criteria: searchCriteria,
      }),
    );
  };
}
